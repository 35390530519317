import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Input, Select, Checkbox, Form, message } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useMutation } from '@apollo/client';
import { Redirect } from 'react-router-dom';
import CREATE_NEW_PRODUCT_COURSE from './queries/CreateNewProductMutation';
import GET_PRODUCT_ITEMS_QUERY from './queries/GetProductItemsQuery';

import './NewCourseModal.css';
import { CourseTypeSelect } from '../courseTypes/CourseTypeSelect';

const NewCourseModal = ({ visible, products, title, onClose }) => {
  const [hasCme, setHasCme] = useState(false);
  const [hasProduct, setHasProduct] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [isBootcampAssessment, setIsBootcampAssessment] = useState(false);
  const [isAddOnAssessment, setIsAddOnAssessment] = useState(false);
  const [existingProductId, setExistingProductId] = useState(null);
  const [isArb, setIsArb] = useState(false);

  const handleHasCmeChange = value => {
    setHasCme(!hasCme);
  };

  const handleHasProductChange = value => {
    setHasProduct(!hasProduct);
  };

  const handleCourseTypeChange = value => {
    if (value === 'Bootcamp Assessment') {
      setIsBootcampAssessment(true);
    } else {
      setIsBootcampAssessment(false);
    }
    form.setFieldsValue({ courseType: value });
  };

  const handleIsAddOnAssessment = value => {
    setIsAddOnAssessment(!isAddOnAssessment);
  };

  const handleIsArbChange = value => {
    setIsArb(!isArb);
  };

  const [createProductCourse] = useMutation(CREATE_NEW_PRODUCT_COURSE, {
    onCompleted: (data, clientOptions) => {
      if (data.createNewProduct) {
        setRedirect('/item/' + data.createNewProduct);
      } else {
        message.error('There was an issue creating the product.');
      }
    },
    refetchQueries: [GET_PRODUCT_ITEMS_QUERY]
  });

  const [form] = Form.useForm();

  const handleClose = () => {
    setHasCme(false);
    setHasProduct(false);
    setIsAddOnAssessment(false);
    setIsBootcampAssessment(false);
    setIsArb(false);

    form.resetFields();
    onClose();
  };

  const validateItemCme = (rule, value) => {
    if (value === true && !hasCme) {
      return Promise.reject(
        'Cannot create an Item with CME for a Product without CME'
      );
    } else {
      return Promise.resolve();
    }
  };

  const handleExistingProductChange = value => {
    setExistingProductId(value);
  };

  const handleSubmit = () => {
    form.validateFields().then(onSuccess => {
      let productId;
      let productVertical = onSuccess.Vertical;
      if (onSuccess.ProductId) {
        const product = products.find(
          product => product.id === onSuccess.ProductId
        );
        productId = onSuccess.ProductId;
        productVertical = product.vertical.id;
      }

      createProductCourse({
        variables: {
          productId,
          productVertical,
          courseTitle: onSuccess.Title,
          courseShortname: onSuccess.Shortname,
          courseHasCme: onSuccess.HasCme,
          courseType: onSuccess.courseType,
          courseMaxCredits: onSuccess.Hours,
          itemTitle: onSuccess.Title,
          itemShortname: onSuccess.ItemShortname,
          itemPrice: onSuccess.Price,
          itemIsCmeEnabled: onSuccess.HasCme,
          itemIsArb: onSuccess.IsArb,
          itemDescription: onSuccess.Description,
          itemSubscriptionLength: onSuccess.SubscriptionLength,
          parentItemId: onSuccess.parentItem
        }
      });
    });
  };

  const hasNoParentItem = item => item.parentId === null;

  const bootcampAssessmentProducts = products.filter(product => {
    const hasBootcampAssessment =
      product.courses.filter(
        course => course.courseType.shortname === 'bootcamp-assessment'
      ).length > 0;

    return hasProduct
      ? hasBootcampAssessment && product?.id === existingProductId
      : hasBootcampAssessment;
  });

  let bootcampParentItemOptionsWithAssessment = [];
  let bootcampParentItemOptionsWithoutAssessment = [];

  bootcampAssessmentProducts.forEach(assessmentProduct => {
    const hasAddOn = assessmentProduct.courses.some(course =>
      course.items.some(item => item.parentId !== null && item.isAddOn)
    );

    const addToBootcampParentItemOptions = optionArray =>
      assessmentProduct.courses.forEach(course => {
        course.items.filter(hasNoParentItem).forEach(item => {
          if (item.course.courseType.shortname !== 'bootcamp-assessment') {
            optionArray.push({
              label:
                item.title +
                ' - ' +
                item.category.toLowerCase().replace(/_/g, ' '),
              value: item.id
            });
          }
        });
      });

    hasAddOn
      ? addToBootcampParentItemOptions(bootcampParentItemOptionsWithAssessment)
      : addToBootcampParentItemOptions(
          bootcampParentItemOptionsWithoutAssessment
        );
  });

  bootcampParentItemOptionsWithoutAssessment = [
    ...new Map(
      bootcampParentItemOptionsWithoutAssessment.map(item => [item.value, item])
    ).values()
  ];
  bootcampParentItemOptionsWithAssessment = [
    ...new Map(
      bootcampParentItemOptionsWithAssessment.map(item => [item.value, item])
    ).values()
  ];

  const productOptions = products
    .filter(product => !product.title.includes('unused'))
    .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
    .map(product => ({
      value: product.id,
      label: product.title
    }));

  if (redirect) {
    return <Redirect to={redirect} />;
  } else {
    return (
      <Modal
        wrapClassName="new-course-modal"
        width="60vw"
        visible={visible}
        title={title}
        onCancel={handleClose}
        footer={
          <>
            <Button onClick={handleClose}>Close</Button>
          </>
        }
      >
        <div>
          <Form
            form={form}
            name="new-course"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={handleSubmit}
            onFinishFailed={handleSubmit}
            autoComplete="off"
          >
            <h5>Product</h5>
            <Form.Item
              label="Assign to product?"
              name="existingProduct"
              valuePropName="checked"
            >
              <Checkbox
                id="hasProduct"
                checked={hasProduct}
                onChange={handleHasProductChange}
              />
            </Form.Item>
            {hasProduct && (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Select a product or uncheck the box above.'
                  }
                ]}
                label="Product"
                name="ProductId"
              >
                <Select
                  id="productId"
                  onChange={handleExistingProductChange}
                  style={{
                    width: 320
                  }}
                  options={productOptions}
                />
              </Form.Item>
            )}
            <hr />
            <div className="new-course-modal__course-section">
              <h5>Course</h5>
              <Form.Item
                rules={[{ required: true, message: 'Must have a type.' }]}
                label="Course Type"
                name="courseType"
              >
                <CourseTypeSelect
                  handleCourseTypeChange={handleCourseTypeChange}
                />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Must have a title!' }]}
                label="Title"
                name="Title"
                extra="Note that this title will be used to set the Item title. The Item title will be set to the first 35 characters."
              >
                <Input id="courseTitleInput" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Must have a shortname!' }]}
                label="Shortname"
                name="Shortname"
              >
                <Input id="courseShortnameInput" />
              </Form.Item>
              {!hasProduct && (
                <Form.Item
                  rules={[
                    { required: true, message: 'Must have a product vertical!' }
                  ]}
                  label="Vertical"
                  name="Vertical"
                >
                  <Select
                    id="courseVerticalSelect"
                    style={{
                      width: 240
                    }}
                    options={[
                      { value: 'Primary Care', label: 'Primary Care' },
                      { value: 'Urgent Care', label: 'Urgent Care' },
                      { value: 'Pediatrics', label: 'Pediatrics' },
                      {
                        value: 'Emergency Medicine',
                        label: 'Emergency Medicine'
                      },
                      { value: 'PA', label: 'PA' },
                      { value: 'More from Hippo', label: 'More from Hippo' }
                    ]}
                  />
                </Form.Item>
              )}
              <Form.Item label="Has CME" name="HasCme" valuePropName="checked">
                <Checkbox
                  id="courseHasCmeInput"
                  checked={hasCme}
                  onChange={handleHasCmeChange}
                />
              </Form.Item>
              {hasCme && (
                <Form.Item label="Hours" name="Hours">
                  <Input
                    style={{ maxWidth: 180 }}
                    id="courseCmeHoursInput"
                    type="number"
                    placeholder="Maximum CME"
                  />
                </Form.Item>
              )}
            </div>
            <hr />
            <h5>Item</h5>
            <Form.Item
              rules={[{ required: true, message: 'Must have a shortname!' }]}
              label="Shortname"
              name="ItemShortname"
            >
              <Input id="itemShortnameInput" />
            </Form.Item>
            {isBootcampAssessment && (
              <>
                <Form.Item
                  label="Is Add On for an existing Item?"
                  name="isAddOnAssessment"
                  valuePropName="checked"
                >
                  <Checkbox
                    id="isAddOnAssessment"
                    checked={isAddOnAssessment}
                    onChange={handleIsAddOnAssessment}
                  />
                </Form.Item>

                {isAddOnAssessment && (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Must have an Item to add-on to'
                      }
                    ]}
                    label="Parent Item"
                    name="parentItem"
                  >
                    <Select
                      id="parentItemSelect"
                      style={{
                        width: 360
                      }}
                      options={[
                        {
                          label: 'No Current Assessment',
                          options: bootcampParentItemOptionsWithoutAssessment
                        },
                        {
                          label: 'Has Current Assessment',
                          options: bootcampParentItemOptionsWithAssessment
                        }
                      ]}
                    />
                  </Form.Item>
                )}
              </>
            )}
            <Form.Item
              rules={[{ required: true, message: 'Must have a description!' }]}
              label="Description"
              name="Description"
              style={{}}
              extra={
                'This is the description that will show for the item in the cart when it appears under "Clinicians also purchased" section.'
              }
            >
              <TextArea id="itemDescriptionInput" rows={2} />
            </Form.Item>
            <Form.Item
              label="CME Enabled"
              name="CmeEnabled"
              valuePropName="checked"
              rules={[{ validator: validateItemCme }]}
            >
              <Checkbox id="itemCmeEnabledInput" />
            </Form.Item>
            <Form.Item
              label="Is Auto Renew Billing (ARB)"
              name="IsArb"
              valuePropName="checked"
            >
              <Checkbox id="isArb" value={isArb} onChange={handleIsArbChange} />
            </Form.Item>
            <Form.Item label="Price" name="Price">
              <Input
                id="itemPriceInput"
                type="number"
                style={{ maxWidth: 100 }}
              />
            </Form.Item>
            <Form.Item label="Subscription Length" name="SubscriptionLength">
              <Select
                id="itemSubscriptionLengthSelect"
                style={{
                  width: 180
                }}
                options={[
                  {
                    value: null,
                    label: 'Non-expiring',
                    disabled: isArb
                  },
                  { value: '1', label: '1 Month' },
                  { value: '3', label: '3 Months' },
                  { value: '12', label: '12 Months' },
                  { value: '24', label: '24 Months' },
                  { value: '36', label: '36 Months' }
                ]}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
};

NewCourseModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func
};

NewCourseModal.Props = {
  visible: false,
  title: '',
  onClose: () => null
};

export default NewCourseModal;
