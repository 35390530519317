import { gql } from '@apollo/client';

const COURSE_SALES_PAGE_QUERY = gql`
  query CourseSalesPageUrlsQuery {
    coursesList(condition: { showOnWebInternal: true }) {
      id
      salesPageUrl
    }
  }
`;

const COURSE_QUERY = gql`
  query CourseQuery($courseId: UUID!) {
    course(id: $courseId) {
      id
      product {
        id
      }
      courseAttachmentBindingsList(condition: { courseId: $courseId }) {
        id
        expiredAt
        activatedAt
        attachment {
          id
          title
          subtitle
          key
          fileName
          updatedAt
          signedUrl
          hasAccountCmeAttempts
        }
      }
      accountEarnedCmesList(condition: { courseId: $courseId }, first: 1) {
        id
      }
    }
  }
`;

const COURSES_LIST_QUERY = gql`
  query CoursesListQuery($courseTypeId: UUID) {
    coursesList(
      condition: { showOnWebInternal: true, courseTypeId: $courseTypeId }
      orderBy: TITLE_ASC
    ) {
      id
      title
      courseType {
        id
      }
    }
  }
`;

const COURSETYPES_LIST_QUERY = gql`
  query CourseTypesListQuery($shortname: String) {
    courseTypesList(condition: { shortname: $shortname }) {
      id
      shortname
    }
  }
`;

export {
  COURSE_SALES_PAGE_QUERY,
  COURSE_QUERY,
  COURSES_LIST_QUERY,
  COURSETYPES_LIST_QUERY
};
