import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { Editor } from '@tinymce/tinymce-react';

/**
 * Note: TinyMCE is vendored outside the app bundle
 * in order self host and adhere to the GPL license.
 */

const TINYMCE_PATH = process.env.PUBLIC_URL + '/vendor/tinymce';

const settings = {
  // FOR FUTURE USE: If you need a different editor plugin configuraiton, add additional named configurations here.
  default: {
    height: 500,
    menubar: false,
    plugins: ['lists', 'paste', 'table', 'link'],
    toolbar: [
      'undo redo | formatselect | bold italic underline superscript subscript ' +
        '| bullist numlist outdent indent | removeformat '
    ],
    // use this to whitelist addtional style properties that the default paste configuration strips out
    paste_retain_style_properties: 'text-decoration'

    // For future debugging of issues with losing format while pasting, uncomment the below settings
    // paste_enable_default_filters: false, // this turns off all of the cleanup tinymce tries to do
    // paste_preprocess: function(plugin, args) {
    //   console.log(args); // filtering occurs before this, so this logs the html that's getting through the filters and into the paste function.
    //   args.content += ' preprocess'; // content-to-be-pasted can be further modified in-place here if just whitelisting styles and tags isn't enough
    // }
    // paste_word_valid_elements: "" // can be used to whitelist additional elements, the default element list can be found by searching for "defaultValidElements" in /node_modules/tinymce/plugins/paste/plugin.js
    // For further reading https://www.tiny.cloud/docs/plugins/opensource/paste/
  }
};

const RichTextEditor = ({
  className,
  defaultValue,
  onChange,
  configurationName = 'default',
  debounceOnChange = true,
  disabled = false
}) => {
  const [editorValue, setEditorValue] = useState(defaultValue);

  const onEditorChange =
    (debounceOnChange && debounce(onChange, 600)) || onChange;
  return (
    <div className={className}>
      <Editor
        value={editorValue}
        disabled={disabled}
        tinymceScriptSrc={`${TINYMCE_PATH}/tinymce.min.js`}
        licenseKey="gpl"
        init={{
          content_css: `${TINYMCE_PATH}/skins/content/default/content.min.css`,
          ...settings[configurationName]
        }}
        onEditorChange={value => {
          setEditorValue(value);
          onEditorChange(value);
        }}
      />
    </div>
  );
};

export default RichTextEditor;
