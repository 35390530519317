import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { sortBy } from 'lodash';
import {
  Breadcrumb,
  Descriptions,
  Select,
  Tooltip,
  Button,
  Checkbox,
  message,
  Card,
  Collapse
} from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { notify } from 'react-notify-toast';
import * as API from '../API';

import TopicGroupView from './course/TopicGroupView';
import EpisodeView from './course/EpisodeView';

import MediaInput from '../components/MediaInput';
import CourseSection from '../components/CourseSection';
import CourseDescriptionSection from '../components/CourseDescriptionSection';
import AttachMediaModal from '../components/Attachments/AttachMediaModal';
import CourseCmeDetail from '../components/CourseCmeDetail';
import AttachmentsCourseGuideInput from '../components/Attachments/AttachmentsCourseGuideInput';
import ResourceLibraryManager from './resourceLibrary/ResourceLibraryManager';
import SingleImageInput from '../components/Upload/SingleImageInput';

import { fetchMediaById, savePosterFrame } from '../utils/media';
import { getDisclosures, getCmeBuckets } from '../utils/cme';
import MessageLoader from '../components/MessageLoader';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { COURSE_QUERY } from './course/queries/CourseQueries';
import { GET_PRODUCT_ITEMS_LIST_QUERY } from './items/queries/GetItemsListQueries';
import { UPDATE_COURSE_MUTATION } from './course/queries/UpdateCourseMutations';
import {
  UPDATE_ACTIVATEDAT_COURSEATTACHMENTBINDING_MUTATION,
  UPDATE_EXPIREDAT_COURSEATTACHMENTBINDING_MUTATION
} from './course/queries/CourseAttachmentBindingMutations';

import './Course.css';

export default function Course() {
  const [courses, setCourses] = useState(null);
  const [tagTypes, setTagTypes] = useState(null);
  const [courseTagTypeBindings, setCourseTagTypeBindings] = useState(null);
  const [primaryTagTypeId, setPrimaryTagTypeId] = useState(null);
  const [assessmentCourseId, setAssessmentCourseId] = useState(null);
  const [secondaryTagTypeIds, setSecondaryTagTypeIds] = useState([]);
  const [additionalTagTypeIds, setAdditionalTagTypeIds] = useState([]);
  const [assessmentCourses, setAssessmentCourses] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [assessmentId, setAssessmentId] = useState(null);
  const [course, setCourse] = useState(null);
  const [productItems, setProductItems] = useState([]);
  const [productId, setProductId] = useState(null);
  const [defaultItemMessage, setDefaultItemMessage] = useState('');
  const [selectedCourseItem, setSelectedCourseItem] = useState(null);
  const [courseType, setCourseType] = useState(null);
  const [product, setProduct] = useState(null);
  const [disclosure, setDisclosure] = useState(null);
  const [cmeBuckets, setCmeBuckets] = useState([]);
  const [disableCmeEarningTypeInput, setDisableCmeEarningTypeInput] = useState(
    false
  );
  const [iconImage, setIconImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);

  // used for showing data when new poster is captured
  const [tempPosterFrame, setTempPosterFrame] = useState('');

  // currently attached media (includes encodings)
  const [attachedMedia, setAttachedMedia] = useState(null);
  const [attachments, setAttachments] = useState(null);
  const [savingMedia, setSavingMedia] = useState(false);
  const [saveMediaForMediaInput, setSaveMediaForMediaInput] = useState(false);
  const [saveMediaForAttachMedia, setSaveMediaForAttachMedia] = useState(false);
  const [item, setItem] = useState(null);
  const [showingMediaModal, setShowingMediaModal] = useState(false);
  const [savingPoster, setSavingPoster] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prevCourseId, setPrevCourseId] = useState(null);

  const cmeEarningTypes = [
    { type: 'Accrual', value: false },
    { type: 'Attestation', value: true }
  ];

  const { id: courseId } = useParams();

  const { data: courseData, loading: loadingCourseData, refetch } = useQuery(
    COURSE_QUERY,
    {
      variables: {
        courseId
      },
      nextFetchPolicy: 'network-only'
    }
  );

  const { data: productItemsData, loading: loadingItems } = useQuery(
    GET_PRODUCT_ITEMS_LIST_QUERY,
    {
      variables: {
        productId
      },
      skip: !productId,
      nextFetchPolicy: 'network-only'
    }
  );

  const [updateActivatedAtCourseAttachmentBinding] = useMutation(
    UPDATE_ACTIVATEDAT_COURSEATTACHMENTBINDING_MUTATION
  );

  const [updateExpiredAtCourseAttachmentBinding] = useMutation(
    UPDATE_EXPIREDAT_COURSEATTACHMENTBINDING_MUTATION
  );

  const [updateCourse] = useMutation(UPDATE_COURSE_MUTATION);

  const onAttach = useCallback(() => {
    Promise.all([
      API.course.update({
        id: courseId,
        attributes: {
          media: {
            type: 'media',
            id: item.id
          }
        }
      }),
      fetchMediaById(item.id)
    ])
      .then(([, loadedMedia]) => {
        const newAttachedMedia = loadedMedia.data.media[0];
        setAttachedMedia(newAttachedMedia);
        setSavingMedia(false);
        setSaveMediaForAttachMedia(false);
        setShowingMediaModal(false);
      })
      .catch(e => {
        window.alert(e.message);
        setSavingMedia(false);
        setSaveMediaForAttachMedia(false);
      });
  }, [courseId, item]);

  const onDetach = useCallback(() => {
    API.course
      .update({
        id: course.id,
        attributes: { media: null }
      })
      .then(() => {
        setAttachedMedia(null);
        setSavingMedia(false);
      })
      .catch(e => {
        window.alert(e.message);
        setSavingMedia(false);
      });
  }, [course]);

  const loadCourseLogoAndIcon = async (logoImage, iconImage) => {
    if (logoImage) {
      const logoImageData = await API.image.find({
        id: logoImage.id,
        options: {}
      });
      setLogoImage(logoImageData.data);
    }
    if (iconImage) {
      const iconImageData = await API.image.find({
        id: iconImage.id,
        options: {}
      });
      setIconImage(iconImageData.data);
    }
  };

  const loadData = useCallback(async () => {
    try {
      setLoading(true);

      const courseResponse = await API.course.find({
        id: courseId,
        options: {
          include:
            'product,media,media.mediaEncodings,courseType,courseTagTypeBindings,courseTagTypeBindings.tagType,assessments'
        }
      });
      const disclosures = await getDisclosures(courseResponse.data, true);
      const cmeBuckets = await getCmeBuckets(courseResponse.data, true);

      courseResponse.data.attributes.defaultItemId &&
        setSelectedCourseItem(courseResponse.data.attributes.defaultItemId);

      let courseTagTypes = courseResponse.included
        .filter(data => data.type === 'tagType')
        .map(tagType => {
          const binding = courseResponse.included.find(
            data =>
              data.type === 'courseTagTypeBinding' &&
              data.relationships.tagType.data.id === tagType.id
          );

          return {
            ...tagType,
            isPrimary: binding.attributes.isPrimary,
            isSecondary: binding.attributes.isSecondary
          };
        });
      courseTagTypes = sortBy(
        courseTagTypes,
        tagType => tagType.attributes.name
      );

      const courseTagTypeBindings = courseResponse.included.filter(
        data => data.type === 'courseTagTypeBinding'
      );

      const primaryTagType = courseTagTypes.find(tagType => tagType.isPrimary);
      const primaryTagTypeId = primaryTagType ? primaryTagType.id : null;
      const secondaryTagTypeIds = courseTagTypes
        .filter(tagType => tagType.isSecondary)
        .map(tagType => tagType.id);
      const additionalTagTypeIds = courseTagTypes
        .filter(tagType => !tagType.isPrimary && !tagType.isSecondary)
        .map(tagType => tagType.id);

      const assessmentCourseId = courseResponse.data.relationships
        .assessmentCourse.data
        ? courseResponse.data.relationships.assessmentCourse.data.id
        : null;

      const assessments = courseResponse.included.filter(
        data => data.type === 'assessment'
      );
      const assessmentId = assessments.find(
        assessment => assessment.attributes.isCourseAssessment === true
      )?.id;

      const courseType = courseResponse.included.find(
        data => data.type === 'courseType'
      );

      const product = courseResponse.included.find(
        data => data.type === 'product'
      );

      const jsonApiMedia = courseResponse.included.find(
        data => data.type === 'media'
      );

      if (
        courseResponse.data.relationships.logoImage.data ||
        courseResponse.data.relationships.iconImage.data
      ) {
        loadCourseLogoAndIcon(
          courseResponse.data.relationships.logoImage.data,
          courseResponse.data.relationships.iconImage.data
        );
      }

      // Convert JSON-API response into simpler object.
      let simpleMedia = null;
      if (jsonApiMedia) {
        simpleMedia = {
          id: jsonApiMedia.id
        };

        Object.keys(jsonApiMedia.attributes).forEach(key => {
          simpleMedia[key] = jsonApiMedia.attributes[key];
        });

        simpleMedia.mediaEncodings = courseResponse.included
          .filter(data => data.type === 'mediaEncoding')
          .map(jsonApiMediaEncoding => {
            const mediaEncoding = {
              id: jsonApiMediaEncoding.id
            };

            Object.keys(jsonApiMediaEncoding.attributes).forEach(key => {
              mediaEncoding[key] = jsonApiMediaEncoding.attributes[key];
            });

            return mediaEncoding;
          });
      }

      const coursesResponse =
        courses ||
        (
          await API.course.where({
            filter: {
              courseType: courseType.id
            },
            options: {
              sort: 'title'
            }
          })
        ).data;

      const tagTypes = await API.fetchAllPages(
        API.tagType.all({
          options: {
            sort: 'name'
          }
        })
      );

      const assessmentCourses = await API.fetchAllPages(
        API.course.where({
          filter: {
            courseType: '28937469-a900-4638-86a5-bc0dac07247d'
          }
        })
      );

      setCourses(coursesResponse);
      setCourse(courseResponse.data);
      setCourseType(courseType);
      setCourseTagTypeBindings(courseTagTypeBindings);
      setPrimaryTagTypeId(primaryTagTypeId);
      setSecondaryTagTypeIds(secondaryTagTypeIds);
      setAdditionalTagTypeIds(additionalTagTypeIds);
      setAssessmentCourseId(assessmentCourseId);
      setAssessmentCourses(assessmentCourses);
      setAssessments(assessments);
      setAssessmentId(assessmentId);
      setProduct(product);
      setAttachedMedia(simpleMedia);
      setDisclosure(disclosures.length ? disclosures[0] : null);
      setCmeBuckets(cmeBuckets.length ? cmeBuckets : []);
      setLoading(false);
      setTagTypes(tagTypes);
    } catch (error) {
      setLoading(false);
      if (error.data) {
        notify.show(error.data.errors[0].title, 'error');
      } else {
        throw error;
      }
    }
  }, [courseId, courses]);

  useEffect(() => {
    if (saveMediaForAttachMedia) {
      onAttach();
    }
    if (saveMediaForMediaInput) {
      onDetach();
    }
  }, [onAttach, onDetach, saveMediaForAttachMedia, saveMediaForMediaInput]);

  useEffect(() => {
    if (courseId !== prevCourseId) {
      setPrevCourseId(courseId);
      loadData();
    }
    if (!loadingCourseData) {
      setAttachments(courseData.course?.courseAttachmentBindingsList);
      setProductId(courseData.course?.product.id);
      if (courseData.course?.accountEarnedCmesList.length > 0) {
        setDisableCmeEarningTypeInput(true);
      }
    }
    if (!loadingItems && productItemsData?.itemsList) {
      setProductItems(productItemsData?.itemsList);
    }
  }, [
    courseData,
    courseId,
    loadData,
    loadingCourseData,
    prevCourseId,
    productItemsData,
    loadingItems
  ]);

  useEffect(() => {
    if (selectedCourseItem && productItems?.length > 0) {
      const getDefaultItemMessage = defaultItemId => {
        const selectedItem = productItems?.find(
          item => item.id === defaultItemId
        );

        let selectedItemMessage = '';
        if (selectedItem?.isAddOn && !selectedItem?.allowStandalone) {
          selectedItemMessage +=
            'This item is an add-on and cannot be purchased standalone. ';
        }
        if (selectedItem?.pricesList.length < 1) {
          selectedItemMessage += 'This item has no price. ';
        } else if (selectedItem?.pricesList[0].price === '0.00') {
          selectedItemMessage += `Item price: $${selectedItem.pricesList[0].price}. `;
        }
        setDefaultItemMessage(selectedItemMessage);
      };

      getDefaultItemMessage(selectedCourseItem);
    }
  }, [selectedCourseItem, productItems]);

  const getCmeDetails = () => {
    const rows = [
      {
        key: 'disclosure',
        title: 'CME Disclosure',
        activatedAt: disclosure ? disclosure.attributes.activatedAt : '(None)',
        expiredAt: disclosure ? disclosure.attributes.expiredAt : '(None)'
      }
    ];

    cmeBuckets.map(bucket => {
      const creditDescription = bucket.relationships.cmeAccreditor
        ? bucket.relationships.cmeAccreditor.attributes.name
        : 'Unknown';

      return rows.push({
        key: bucket.id,
        title: `${bucket.attributes.title} Credit (${creditDescription})`,
        activatedAt: bucket.attributes.activatedAt,
        expiredAt: bucket.attributes.expiredAt
      });
    });

    return rows;
  };

  const updateAttachmentActivatedAtOnClick = async (
    courseAttachmentBindingId,
    activatedAt
  ) => {
    try {
      const activatedAtUTC = moment
        .utc(moment(activatedAt).startOf('day'))
        .startOf('day');
      await updateActivatedAtCourseAttachmentBinding({
        variables: {
          courseAttachmentBindingId,
          activatedAt: activatedAtUTC,
          updatedAt: new Date()
        }
      });
    } catch (e) {
      message.error('Error while updating attachment activatedAt', 2);
    }
  };

  const updateAttachmentExpiredAtOnClick = async (
    courseAttachmentBindingId,
    expiredAt
  ) => {
    try {
      const expiredAtUTC = moment
        .utc(moment(expiredAt).startOf('day'))
        .startOf('day');
      await updateExpiredAtCourseAttachmentBinding({
        variables: {
          courseAttachmentBindingId,
          expiredAt: expiredAtUTC,
          updatedAt: new Date()
        }
      });
    } catch (e) {
      message.error('Error while updating attachment expiredAt', 2);
    }
  };

  const savePosterFrameMedia = () => {
    setSavingPoster(true);
    (async () => {
      savePosterFrame(
        '#lecture-media video',
        attachedMedia,
        posterFrameMedia => {
          // Update currently attached media.
          const updatedAttachedMedia = attachedMedia
            ? {
                ...attachedMedia,
                posterFrame: posterFrameMedia.posterFrame
              }
            : null;

          setAttachedMedia(updatedAttachedMedia);
          setTempPosterFrame('');
          setSavingPoster(false);
        },
        e => {
          window.alert(e.message);
          setSavingPoster(false);
        },
        tempPosterFrame => {
          setTempPosterFrame(tempPosterFrame);
        }
      );
    })();
  };

  const deleteCourseTagTypeBinding = async tagTypeId => {
    const binding = courseTagTypeBindings.find(
      binding => binding.relationships.tagType.data.id === tagTypeId
    );
    await API.courseTagTypeBinding.delete({ id: binding.id });

    const updatedCourseTagTypeBindings = courseTagTypeBindings.filter(
      tagTypeBinding => tagTypeBinding.id !== binding.id
    );
    const secondaryTagTypeIds = updatedCourseTagTypeBindings
      .filter(binding => binding.attributes.isSecondary)
      .map(binding => binding.relationships.tagType.data.id);
    const additionalTagTypeIds = updatedCourseTagTypeBindings
      .filter(
        binding =>
          !binding.attributes.isPrimary && !binding.attributes.isSecondary
      )
      .map(binding => binding.relationships.tagType.data.id);

    setCourseTagTypeBindings(updatedCourseTagTypeBindings);
    setSecondaryTagTypeIds(secondaryTagTypeIds);
    setAdditionalTagTypeIds(additionalTagTypeIds);
  };

  const shouldDisableTagType = (tagTypeId, isPrimary, isSecondary) =>
    courseTagTypeBindings.find(
      binding =>
        binding.relationships.tagType.data.id === tagTypeId &&
        (binding.attributes.isPrimary !== isPrimary ||
          binding.attributes.isSecondary !== isSecondary)
    );

  const createCourseTagTypeBinding = async (
    tagTypeId,
    isPrimary,
    isSecondary
  ) => {
    const binding = (
      await API.courseTagTypeBinding.create({
        attributes: {
          isPrimary,
          isSecondary
        },
        relationships: {
          course: {
            data: {
              type: 'course',
              id: course.id
            }
          },
          tagType: {
            data: {
              type: 'tagType',
              id: tagTypeId
            }
          }
        }
      })
    ).data;

    const updatedCourseTagTypeBindings = [...courseTagTypeBindings, binding];

    if (isPrimary) {
      setPrimaryTagTypeId(tagTypeId);
      setCourseTagTypeBindings(updatedCourseTagTypeBindings);
    } else if (isSecondary) {
      const updatedSecondaryTagTypeIds = [...secondaryTagTypeIds, tagTypeId];

      setSecondaryTagTypeIds(updatedSecondaryTagTypeIds);
      setCourseTagTypeBindings(courseTagTypeBindings);
    } else {
      const updatedAdditionalTagTypeIds = [...additionalTagTypeIds, tagTypeId];
      setAdditionalTagTypeIds(updatedAdditionalTagTypeIds);
      setCourseTagTypeBindings(courseTagTypeBindings);
    }
  };

  const handlePrimaryTagSelect = async value => {
    // deselect previous tag type
    if (primaryTagTypeId) {
      await deleteCourseTagTypeBinding(primaryTagTypeId);
    }
    await createCourseTagTypeBinding(value, true);
  };

  const handleTagChange = async (value, isSecondary) => {
    const currentTags = isSecondary
      ? secondaryTagTypeIds
      : additionalTagTypeIds;

    if (value.length < currentTags.length) {
      // A secondary tag was removed. Delete the tag
      const tagTypeIdToRemove = currentTags.find(
        tagTypeId => !value.includes(tagTypeId)
      );
      await deleteCourseTagTypeBinding(tagTypeIdToRemove);
    } else {
      // A secondary tag was added. Add the tag and binding.
      const tagTypeIdToAdd = value.find(
        tagTypeId => !currentTags.includes(tagTypeId)
      );
      await createCourseTagTypeBinding(tagTypeIdToAdd, false, isSecondary);
    }
  };

  const handleAssessmentCourseChange = async value => {
    const data = value
      ? {
          type: 'course',
          id: value
        }
      : null;

    await API.course.update({
      id: course.id,
      relationships: {
        assessmentCourse: {
          data
        }
      }
    });
    setAssessmentCourseId(value);
  };

  const handleDefaultCourseItemChange = async value => {
    setSelectedCourseItem(value);
    await updateCourse({
      variables: {
        id: course.id,
        defaultItemId: value,
        updatedAt: new Date()
      }
    });

    setCourse({
      ...course,
      attributes: {
        ...course.attributes,
        defaultItemId: value
      }
    });
  };

  const handleAssessmentChange = async value => {
    await API.assessment.update({
      id: value,
      attributes: {
        isCourseAssessment: true
      }
    });
    for (const assessment of assessments) {
      if (assessment.id !== value) {
        await API.assessment.update({
          id: assessment.id,
          attributes: {
            isCourseAssessment: false
          }
        });
      }
    }
    setAssessmentId(value);
  };

  const handleTitleChange = async value => {
    await API.course.update({
      id: course.id,
      attributes: {
        title: value
      }
    });
  };

  const handleShowInAccountMenuChange = async value => {
    await API.course.update({
      id: course.id,
      attributes: {
        showInAccountMenu: value
      }
    });
    setCourse({
      ...course,
      attributes: {
        ...course.attributes,
        showInAccountMenu: value
      }
    });
  };

  const handleShowOnWebChange = async value => {
    await API.course.update({
      id: course.id,
      attributes: {
        showOnWeb: value
      }
    });
    setCourse({
      ...course,
      attributes: {
        ...course.attributes,
        showOnWeb: value
      }
    });
  };

  const handleShowOnWebInternalChange = async value => {
    await API.course.update({
      id: course.id,
      attributes: {
        showOnWebInternal: value
      }
    });
    setCourse({
      ...course,
      attributes: {
        ...course.attributes,
        showOnWebInternal: value
      }
    });
  };

  const handleShowOnNativeChange = async value => {
    await API.course.update({
      id: course.id,
      attributes: {
        showOnNative: value
      }
    });
    setCourse({
      ...course,
      attributes: {
        ...course.attributes,
        showOnNative: value
      }
    });
  };

  const handleShowOnNativeInternalChange = async value => {
    await API.course.update({
      id: course.id,
      attributes: {
        showOnNativeInternal: value
      }
    });
    setCourse({
      ...course,
      attributes: {
        ...course.attributes,
        showOnNativeInternal: value
      }
    });
  };

  const handleEnableForGroupsChange = async value => {
    await API.course.update({
      id: course.id,
      attributes: {
        enableForGroups: value
      }
    });
    setCourse({
      ...course,
      attributes: {
        ...course.attributes,
        enableForGroups: value
      }
    });
  };

  const handleSalesPageUrlChange = async value => {
    await API.course.update({
      id: course.id,
      attributes: {
        salesPageUrl: value
      }
    });
    setCourse({
      ...course,
      attributes: {
        ...course.attributes,
        salesPageUrl: value
      }
    });
  };

  const handleCmeEarningTypeChange = async value => {
    await API.course.update({
      id: course.id,
      attributes: {
        attestationCme: value
      }
    });
    setCourse({
      ...course,
      attributes: {
        ...course.attributes,
        attestationCme: value
      }
    });
  };

  const updateLogoImage = async image => {
    await API.course.update({
      id: course.id,
      attributes: {
        LogoImageId: image ? image.id : null
      }
    });
    setLogoImage(image);
  };

  const updateIconImage = async image => {
    await API.course.update({
      id: course.id,
      attributes: {
        IconImageId: image ? image.id : null
      }
    });
    setIconImage(image);
  };

  const courseTypeShortname = courseType && courseType.attributes.shortname;

  return (
    <div>
      <Breadcrumb
        tag="nav"
        style={{
          padding: '20px 0'
        }}
      >
        <Breadcrumb.Item>
          <Link to="/dashboard">Hippo Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/courses">Courses</Link>
        </Breadcrumb.Item>
        {courseType && (
          <Breadcrumb.Item>
            <Link to={`/courses/${courseTypeShortname}`}>
              {courseType.attributes.title}
            </Link>
          </Breadcrumb.Item>
        )}
        {course ? (
          <Breadcrumb.Item active="true">
            {course.attributes.title}
          </Breadcrumb.Item>
        ) : null}
      </Breadcrumb>
      <hr />

      {loading ? (
        <MessageLoader />
      ) : (
        <div className="course">
          {course ? (
            <>
              <h3>{course.attributes.title}</h3>

              <Collapse size="large">
                <Collapse.Panel key="1" header="Course Settings">
                  <Descriptions
                    size="middle"
                    column={1}
                    bordered={true}
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '5px'
                    }}
                  >
                    <Descriptions.Item label="Title">
                      <input
                        type="text"
                        value={course.attributes.title}
                        onChange={e =>
                          setCourse({
                            ...course,
                            attributes: {
                              ...course.attributes,
                              title: e.target.value
                            }
                          })
                        }
                        onBlur={e => handleTitleChange(e.target.value)}
                        className="course__title-input"
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Shortname">
                      {course.attributes.shortname}
                    </Descriptions.Item>
                    <Descriptions.Item label="Product">
                      {product.attributes.title}
                    </Descriptions.Item>

                    <Descriptions.Item
                      label={
                        <div className="course__tag-system-label">
                          <div>Default Item</div>
                          <Tooltip title="Select which Item to default purchases to.">
                            <InfoCircleFilled className="course__tag-system-label-icon" />
                          </Tooltip>
                        </div>
                      }
                    >
                      <Select
                        defaultValue={course.attributes.defaultItemId}
                        placeholder="Select Default Item"
                        onChange={value => handleDefaultCourseItemChange(value)}
                        allowClear={true}
                        className="course__select"
                      >
                        {productItems &&
                          productItems.map(item => (
                            <Select.Option key={item.id}>
                              {`${item.sku}: ${item.title}`}
                            </Select.Option>
                          ))}
                      </Select>
                      <p className="course__default-item-message">
                        {defaultItemMessage}
                      </p>
                    </Descriptions.Item>
                    {(courseTypeShortname === 'video' ||
                      courseTypeShortname === 'podcast') && (
                      <Descriptions.Item
                        label={
                          <div className="course__tag-system-label">
                            <div>Assessment Course</div>
                            <Tooltip title="Select which course is paired with this course as this course's assessment.">
                              <InfoCircleFilled className="course__tag-system-label-icon" />
                            </Tooltip>
                          </div>
                        }
                      >
                        <Select
                          defaultValue={assessmentCourseId}
                          placeholder="Select Assessment Course"
                          onChange={value =>
                            handleAssessmentCourseChange(value)
                          }
                          allowClear={true}
                          className="course__select"
                        >
                          {assessmentCourses &&
                            assessmentCourses.map(course => (
                              <Select.Option key={course.id}>
                                {course.attributes.title}
                              </Select.Option>
                            ))}
                        </Select>
                      </Descriptions.Item>
                    )}
                    {courseTypeShortname === 'video' && (
                      <Descriptions.Item label="CME Earning Type">
                        <Select
                          defaultValue={
                            course.attributes.attestationCme
                              ? course.attributes.attestationCme
                              : 'Accrual'
                          }
                          placeholder="Select CME Earning Type"
                          onChange={value => handleCmeEarningTypeChange(value)}
                          disabled={disableCmeEarningTypeInput}
                          allowClear={false}
                          className="course__select"
                        >
                          {cmeEarningTypes.map(cmeEarningType => (
                            <Select.Option
                              key={cmeEarningType.value}
                              value={cmeEarningType.value}
                            >
                              {cmeEarningType.type}
                            </Select.Option>
                          ))}
                        </Select>
                        {disableCmeEarningTypeInput && (
                          <div className="course_disabled-cme-menu-disaclaimer">
                            Changing CME Earning Type is not possible because
                            users have already earned CME for this course.
                          </div>
                        )}
                      </Descriptions.Item>
                    )}
                    <Descriptions.Item label="Logo Image">
                      <SingleImageInput
                        name="logoImage"
                        value={logoImage}
                        onChange={image => {
                          updateLogoImage(image);
                        }}
                        imgClassName="single-attachment-input__preview-image__logo-image"
                        path={`images/logos/${course.attributes.shortname}`}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item label="Icon Image">
                      <SingleImageInput
                        name="iconImage"
                        value={iconImage}
                        onChange={image => {
                          updateIconImage(image);
                        }}
                        imgClassName="single-attachment-input__preview-image__icon-image"
                        path={`images/logos/${course.attributes.shortname}`}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                  <CourseDescriptionSection title="Visibility">
                    <Descriptions.Item label="Show in Account Menu?">
                      <Checkbox
                        onChange={e =>
                          handleShowInAccountMenuChange(e.target.checked)
                        }
                        checked={course.attributes.showInAccountMenu}
                      />{' '}
                    </Descriptions.Item>
                    <Descriptions.Item label="Show on Web?">
                      <Checkbox
                        onChange={e => handleShowOnWebChange(e.target.checked)}
                        checked={course.attributes.showOnWeb}
                      />{' '}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <div className="course__tag-system-label">
                          <div>Show on Web Internal?</div>
                          <Tooltip
                            title="Selecting this checkbox makes the course available for assessments, questions,
                          and orders"
                          >
                            <InfoCircleFilled className="course__tag-system-label-icon" />
                          </Tooltip>
                        </div>
                      }
                    >
                      <Checkbox
                        onChange={e =>
                          handleShowOnWebInternalChange(e.target.checked)
                        }
                        checked={course.attributes.showOnWebInternal}
                      />{' '}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={
                        <div className="course__tag-system-label">
                          <div>Enable for Groups?</div>
                          <Tooltip title="Selecting this checkbox makes the course available for group subscriptions">
                            <InfoCircleFilled className="course__tag-system-label-icon" />
                          </Tooltip>
                        </div>
                      }
                    >
                      <Checkbox
                        onChange={e => {
                          handleEnableForGroupsChange(e.target.checked);
                        }}
                        checked={course.attributes.enableForGroups}
                      />{' '}
                    </Descriptions.Item>
                    <Descriptions.Item label="Show on Native? (Doesn't affect anything yet)">
                      <Checkbox
                        onChange={e =>
                          handleShowOnNativeChange(e.target.checked)
                        }
                        checked={course.attributes.showOnNative}
                      />{' '}
                    </Descriptions.Item>
                    <Descriptions.Item label="Show on Native Internal? (Doesn't affect anything yet)">
                      <Checkbox
                        onChange={e =>
                          handleShowOnNativeInternalChange(e.target.checked)
                        }
                        checked={course.attributes.showOnNativeInternal}
                      />{' '}
                    </Descriptions.Item>
                    <Descriptions.Item label="Sales Page URL">
                      <input
                        type="text"
                        value={course.attributes.salesPageUrl}
                        onChange={e =>
                          setCourse({
                            ...course,
                            attributes: {
                              ...course.attributes,
                              salesPageUrl: e.target.value
                            }
                          })
                        }
                        onBlur={e => handleSalesPageUrlChange(e.target.value)}
                        className="course__sales-page-url-input"
                      />
                    </Descriptions.Item>
                  </CourseDescriptionSection>

                  <CourseDescriptionSection title="Tags">
                    {courseTagTypeBindings && (
                      <Descriptions.Item
                        label={
                          <div className="course__tag-system-label">
                            <div>Primary Tag System</div>
                            <Tooltip
                              title="The primary tag system will organize the user-facing content as the main topics and icons.
                          Note: if this is not a review product, select Hippo tag"
                            >
                              <InfoCircleFilled className="course__tag-system-label-icon" />
                            </Tooltip>
                          </div>
                        }
                      >
                        <Select
                          defaultValue={
                            primaryTagTypeId
                              ? primaryTagTypeId
                              : 'Select Primary Tag System'
                          }
                          allowClear={true}
                          onChange={value => handlePrimaryTagSelect(value)}
                          className="course__select"
                        >
                          {tagTypes &&
                            tagTypes.map(type => (
                              <Select.Option
                                key={type.id}
                                disabled={shouldDisableTagType(type.id, true)}
                              >
                                {type.attributes.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Descriptions.Item>
                    )}
                    {courseTagTypeBindings && (
                      <Descriptions.Item
                        label={
                          <div className="course__tag-system-label">
                            <div>Secondary Tag System</div>
                            <Tooltip title="Expand the available tag selection for any content in this course to more tag systems, e.g. for searching or reporting purposes (usage varies by course and course type)">
                              <InfoCircleFilled className="course__tag-system-label-icon" />
                            </Tooltip>
                          </div>
                        }
                      >
                        <Select
                          onChange={value => handleTagChange(value, true)}
                          defaultValue={secondaryTagTypeIds}
                          mode="multiple"
                          placeholder="Select Secondary Tag System"
                          className="course__select"
                        >
                          {tagTypes &&
                            tagTypes.map(type => (
                              <Select.Option
                                key={type.id}
                                disabled={shouldDisableTagType(
                                  type.id,
                                  false,
                                  true
                                )}
                              >
                                {type.attributes.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Descriptions.Item>
                    )}
                    {courseTagTypeBindings && (
                      <Descriptions.Item
                        label={
                          <div className="course__tag-system-label">
                            <div>Additional Tag System</div>
                            <Tooltip title="Additional Tag systems are used for internal organizational purposes only.">
                              <InfoCircleFilled className="course__tag-system-label-icon" />
                            </Tooltip>
                          </div>
                        }
                      >
                        <Select
                          onChange={value => handleTagChange(value, false)}
                          defaultValue={additionalTagTypeIds}
                          mode="multiple"
                          placeholder="Select Additional Tag System"
                          className="course__select"
                        >
                          {tagTypes &&
                            tagTypes.map(type => (
                              <Select.Option
                                key={type.id}
                                disabled={shouldDisableTagType(
                                  type.id,
                                  false,
                                  false
                                )}
                              >
                                {type.attributes.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Descriptions.Item>
                    )}
                  </CourseDescriptionSection>
                </Collapse.Panel>
              </Collapse>
            </>
          ) : null}

          {course && (
            <CourseDescriptionSection title="Comments and Ratings">
              {course &&
                (courseTypeShortname === 'podcast' ||
                  courseTypeShortname === 'video') && (
                  <Descriptions.Item
                    label={
                      <div className="course__tag-system-label">
                        <div>
                          {courseTypeShortname === 'podcast'
                            ? 'Chapter Comments'
                            : 'Lecture Comments'}
                        </div>
                      </div>
                    }
                  >
                    <Button>
                      <Link to={`/comments/${course.id}`}>Manage Comments</Link>
                    </Button>
                  </Descriptions.Item>
                )}
              <Descriptions.Item
                label={
                  <div className="course__tag-system-label">
                    <div>Ratings</div>
                  </div>
                }
              >
                <Button>
                  <Link to={`/course/${course.id}/ratings`}>View Ratings</Link>
                </Button>
              </Descriptions.Item>
            </CourseDescriptionSection>
          )}

          {course && (
            <CourseCmeDetail course={course} cmeDetails={getCmeDetails()} />
          )}

          {course &&
          (courseTypeShortname === 'other' || // similar to above, 'other' is what board review courses are at the time of writing this
            courseTypeShortname === 'video') ? ( // eventually this will be changed to 'video' so preparing it now. we can remove 'other' from this conditional formatting at that time
            <ResourceLibraryManager courseId={course.id} />
          ) : (
            // 'other' is what board review courses are -- eventually this will be changed to 'video'
            // we can remove 'other' from this conditional formatting at that time
            <CourseDescriptionSection title="Ebook Uploader (Peds RAP and PANRE-LA only)">
              <Card loading={attachments === null}>
                {attachments && (
                  <AttachmentsCourseGuideInput
                    attachments={attachments}
                    onCreateItem={async attachmentId => {
                      await API.courseAttachmentBinding.create({
                        attributes: {
                          createdAt: new Date(),
                          updatedAt: new Date()
                        },
                        relationships: {
                          course: {
                            data: {
                              type: 'course',
                              id: course.id
                            }
                          },
                          attachment: {
                            data: {
                              type: 'attachment',
                              id: attachmentId
                            }
                          }
                        }
                      });
                      await refetch();
                    }}
                    onUpdateItem={async () => {}}
                    updateActivatedAt={updateAttachmentActivatedAtOnClick}
                    updateExpiredAt={updateAttachmentExpiredAtOnClick}
                    onDeleteItem={async () => {}}
                    showCmeFields={true}
                    canEdit={true}
                  />
                )}
              </Card>
            </CourseDescriptionSection>
          )}

          {/* ************** */}
          {/* Course Content */}
          {/* ************** */}

          {course &&
            (courseTypeShortname === 'qbank' ||
              courseTypeShortname === 'practice-exam') && (
              <CourseSection title="Course Questions">
                <Card>
                  <h6>
                    To edit course questions, go to Content &gt; Questions and
                    filter by this course
                  </h6>
                  <Link to="/content/questions">Edit Questions</Link>
                </Card>
              </CourseSection>
            )}

          {course && courseTypeShortname === 'assessment' && (
            <CourseSection title="Course Content">
              <Card>
                <h6>
                  To edit this course's assessment(s), go to Content &gt;
                  Assessment and filter by this course
                </h6>
                <Link to="/content/assessments">Edit Assessments</Link>
              </Card>
              <Card>
                <h6>
                  To edit course questions, go to Content &gt; Questions and
                  filter by this course
                </h6>
                <Link to="/content/questions">Edit Questions</Link>
              </Card>
            </CourseSection>
          )}

          {course && courseTypeShortname === 'bootcamp-assessment' && (
            <CourseSection title="Course Content">
              <Card
                title={
                  <div className="course__tag-system-label">
                    <div>Assessment</div>
                    <Tooltip title="Select which assessment (questions) to show for this course's content.">
                      <InfoCircleFilled className="course__tag-system-label-icon" />
                    </Tooltip>
                  </div>
                }
              >
                <Select
                  defaultValue={
                    assessmentId ? assessmentId : 'Select Assessment'
                  }
                  onChange={value => handleAssessmentChange(value)}
                  className="course__select"
                >
                  {assessments &&
                    assessments.map(assessment => (
                      <Select.Option key={assessment.id}>
                        {assessment.attributes.title}
                      </Select.Option>
                    ))}
                </Select>
              </Card>
              <Card>
                <h6>
                  To edit this course's assessment(s), go to Content &gt;
                  Assessment and filter by this course
                </h6>
                <Link to="/content/assessments">Edit Assessments</Link>
              </Card>
              <Card>
                <h6>
                  To edit course questions, go to Content &gt; Questions and
                  filter by this course
                </h6>
                <Link to="/content/questions">Edit Questions</Link>
              </Card>
            </CourseSection>
          )}

          {course && courseTypeShortname === 'podcast' && (
            <CourseSection title="Course Episodes">
              <EpisodeView
                defaultCourseId={course.id}
                course={course}
                courseList={courses}
              />
            </CourseSection>
          )}

          {course && courseTypeShortname === 'video' && (
            <>
              <CourseSection title="Welcome Video">
                <Card>
                  <MediaInput
                    selectedMedia={attachedMedia}
                    isSignedUrl={false}
                    posterFrame={
                      tempPosterFrame ||
                      (attachedMedia && attachedMedia.posterFrame)
                    }
                    isSavingPoster={savingPoster}
                    onSelectMedia={() => setShowingMediaModal(true)}
                    onCapturePoster={savePosterFrameMedia}
                    onDetach={() => {
                      setSavingMedia(true);
                      setSaveMediaForMediaInput(true);
                    }}
                  />
                </Card>
                <AttachMediaModal
                  visible={showingMediaModal}
                  courseType="video"
                  isLoading={savingMedia}
                  title="Select Welcome Video"
                  onClose={() => setShowingMediaModal(false)}
                  onAttach={item => {
                    setSavingMedia(true);
                    setItem(item);
                    setSaveMediaForAttachMedia(true);
                  }}
                />
              </CourseSection>
              <TopicGroupView
                courseId={course.id}
                attestationCme={course.attributes.attestationCme}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
